<template>
    <div class="card tcs-card elevate-2 floating-panel" ref="panel" @mousedown="$emit('update:active', myId)">
        <div class="d-flex px-2 justify-content-between border-bottom py-1 align-items-center panel-title" @mousedown="startMoving">
            <div><strong>{{ title }}</strong></div>
            <div class="d-flex">
                <button class="btn btn-sm btn-light rounded-circle p-0 max" @mousedown.stop @click="max"><Maximize2Icon size="18" /></button>
                <button class="btn btn-sm btn-light rounded-circle p-0 min" @mousedown.stop @click="min"><Minimize2Icon size="18" /></button>
                <button class="btn btn-sm btn-light rounded-circle p-0" @mousedown.stop @click="close"><x-icon /></button>
            </div>
        </div>
        <div class="p-2 panel-content">
            <slot></slot>
        </div>
        <div class="d-flex justify-content-end">
            <div class="resize" @mousedown="startResizing"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        width: {
            type: Number,
            default: 350,
        },
        height: {
            type: Number,
            default: 450,
        },
        top: {
            type: Number,
            default: 0,
        },
        left: {
            type: Number,
            default: 0,
        },
        active: {
            type: Number,
            default: 1,
        },
    },
    data: () => ({ myId: Math.random() }),
    methods: {
        close() {
            this.min();
            this.$emit('close');
        },
        max() {
            this.$el.requestFullscreen({
                navigationUI: 'hide',
            });
        },
        min() {
            if (document.fullscreenElement === this.$el)
                document.exitFullscreen();
        },
        resetTop() {
            let panel = this.$refs.panel;
            panel.style.top = this.top + 'px';
        },
        resetLeft() {
            let panel = this.$refs.panel;
            panel.style.left = this.left + 'px';
        },
        resetWidth() {
            let panel = this.$refs.panel;
            panel.style.width = this.width + 'px';
        },
        resetHeight() {
            let panel = this.$refs.panel;
            panel.style.height = this.height + 'px';
        },
        resetActive() {
            let panel = this.$refs.panel;
            panel.style.zIndex = this.active === this.myId ? 889 : 888;
        },
        startMoving(ev) {
            if (ev.button !== 0 || document.fullscreenElement === this.$el) return;
            let panel = this.$refs.panel;
            let parent = panel.parentElement;
            while (parent.tagName.toLowerCase() === 'span') parent = parent.parentElement;
            let {
                offsetLeft: panelLeft,
                offsetTop: panelTop,
                offsetWidth: panelWidth,
            } = panel;
            let {
                offsetWidth: parentWidth,
                offsetHeight: parentHeight,
            } = parent;

            let maxX = parentWidth  - panelWidth;
            let maxY = parentHeight - 45;

            let { x, y } = ev;

            const getNewPosition = (mouseX, mouseY) => {
                let left = panelLeft + (mouseX - x);
                let top = panelTop + (mouseY - y);

                top = top < 0    ? 0
                    : top > maxY ? maxY
                    : top;

                left = left < 0    ? 0
                     : left > maxX ? maxX
                     : left;

                return { top, left };
            };

            const movePanel = ev => {
                const { left, top } = getNewPosition(ev.x, ev.y);
                panel.style.top  = top + 'px';
                panel.style.left = left + 'px';
            };

            const stopMoving = ev => {
                const { left, top } = getNewPosition(ev.x, ev.y);
                panel.style.top  = top  + 'px';
                panel.style.left = left + 'px';
                this.$emit('update:left', left);
                this.$emit('update:top',  top);
                document.removeEventListener('mousemove', movePanel);
                document.removeEventListener('mouseup', stopMoving);
            };

            document.addEventListener('mousemove', movePanel);
            document.addEventListener('mouseup', stopMoving);
        },
        startResizing(ev) {
            if (ev.button !== 0) return;
            let panel = this.$refs.panel;
            let parent = panel.parentElement;
            let {
                offsetWidth: panelWidth,
                offsetLeft: panelLeft,
                offsetHeight: panelHeight,
            } = panel;

            let {
                offsetWidth: parentWidth,
            } = parent;

            let maxX = parentWidth  - panelLeft;
            let { x, y } = ev;

            const getNewSize = (mouseX, mouseY) => {
                let width = panelWidth + (mouseX - x);
                let height =  panelHeight + (mouseY - y);
                width = width > maxX ? maxX : width;
                return { height, width };
            };
            const resizePanel = ev => {
                const { width, height } = getNewSize(ev.x, ev.y);
                panel.style.height  = height  + 'px';
                panel.style.width   = width + 'px';
            }
            const stopResizing = ev => {
                const { width, height } = getNewSize(ev.x, ev.y);
                panel.style.height  = height  + 'px';
                panel.style.width   = width + 'px';
                document.removeEventListener('mousemove', resizePanel);
                document.removeEventListener('mouseup', stopResizing);
                this.$emit('resize');
            }
            document.addEventListener('mousemove', resizePanel);
            document.addEventListener('mouseup', stopResizing);
        }
    },
    watch: {
        top: 'resetTop',
        left: 'resetLeft',
        width: 'resetWidth',
        height: 'resetHeight',
        active: 'resetActive',
    },
    mounted () {
        this.$el.addEventListener('fullscreenchange', () => {
            this.$emit('resize');
        });
        this.resetTop();
        this.resetLeft();
        this.resetWidth();
        this.resetHeight();
        this.resetActive();
    }
}
</script>

<style lang="scss" scoped>
    .floating-panel {
        position: absolute;
        z-index: 888;
        min-width: 150px;
        min-height: 150px;

        .panel-title {
            cursor: move;
            user-select: none;
        }
        .panel-content {
            overflow: auto;
            height: calc(100% - 0.75rem);
            position: relative;
        }
        .resize {
            cursor: nwse-resize;
            user-select: none;
            width: 1rem;
            height: 0.75rem;
            background: url('../../assets/img/resize.svg') no-repeat center center;
            background-clip: content-box;
            border-bottom-right-radius: 10px;
            background-size: cover;
        }
    }
    .min, .max {
        width:  26px;
        height: 26px;
        margin-right: 0.25rem;
    }
    .min {
        display: none;
    }
    .max {
        display: block;
    }
    .floating-panel:fullscreen {
        .min {
            display: block;
        }
        .max {
            display: none;
        }
        .resize {
            display: none;
        }
        .panel-title {
            cursor: auto;
        }
    }
</style>
