<template>
     <template-base>
        <div class="d-flex">
            <h1 class="align-self-center"> Árvore de áreas</h1>
            <div class="spacer"></div>
            <button class="btn btn-success text-white align-self-center" :data-cy="`Adicionar área`" @click="adicionar">Adicionar área principal</button>
        </div>
        <form @submit.prevent.stop="search">
            <div class="container-pesquisa mb-1">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text">Pesquisar</div>
                    </div>
                    <input type="search" class="form-control" aria-label="Filtrar por texto" v-model="query" placeholder="Digite para filtrar por todos os campos" data-cy="Pesquisa">
                    <div class="input-group-append">
                        <button class="btn btn-primary px-4" type="submit" data-cy="Pesquisar"><SearchIcon /></button>
                    </div>
                </div>
                <slot name="results-page" />
            </div>
        </form>
        <div class="mt-2">
            <Tree controls :loading="loading" :items="items" @cancelar-novo="cancelarNovo" @apagar="apagar" @adicionado-novo="adicionadoNovo" @escolher-zoom="exibirMapa"/>
        </div>
        <transition name="fade">
            <floating-panel ref="floating-panel" title="Selecione uma área" :left="20" :top="20" :width="800" :height="500" v-show="mapa" @close="mapa=false">
                <object ref="mapa" :data="mapaUrl" type="image/svg+xml" style="width:100%; height:calc(100% - 0.75rem)" @load="onLoad"></object>
            </floating-panel>
        </transition>
     </template-base>
</template>

<script>
import TemplateBase from '@/templates/Base';
import Tree from '@/components/TreeArea';
import { verifyPermitions } from '@/helpers/auth';
import FloatingPanel from "@/components/Sinotico/FloatingPanel";

import axios from 'axios'
import api from '@/api.js'

export default {
    components: {
        TemplateBase,
        Tree,
        FloatingPanel,
    },
    data () {
        return {
            mapaUrl: require('@/assets/img/selecao-area.svg'),
            items: [],
            query: '',
            areas: [],
            areasFilter: [],

            loading: false,
            criandoItem: false,

            mapa: false,
            areaZoom: null,
        };
    },
    mounted () {
        this.treeAreas('');
    },
    methods: {
        escolherZoom (zoom) {
            if (this.areaZoom) this.areaZoom.nZoom = zoom;
            this.$refs['floating-panel'].close();
        },
        onLoad () {
            const map = this.$refs.mapa.contentDocument;
            for (let i = 1; i <= 15; i++) {
                let el = map.getElementById('zoom' + String(i).padStart(2,'0'));
                if (el) {
                    el.addEventListener('click', () => {
                        this.escolherZoom(i);
                    });
                    el.style.cursor = 'pointer';
                }
            }
        },
        exibirMapa (item) {
            this.areaZoom = item;
            this.mapa = true;
        },
        async treeAreas (q) {
            this.loading = true;
            let areas = (await axios.get(api.v1.area.listRecursive(q))).data;
            let treeNodes = {};
            let forest = [];
            for (let area of areas) {
                if (area.idAreaPai == null) {
                    forest.push(area);
                } else if (treeNodes[area.idAreaPai]) {
                    treeNodes[area.idAreaPai].push(area);
                } else {
                    treeNodes[area.idAreaPai] = [area];
                }
            }
            for (let area of areas) {
                delete area.idAreaPai;
                delete area.createdAt;
                delete area.updatedAt;
                area.opened = false;
                area.checked = false;
                area.editing = false;
                area.children = treeNodes[area.id] || [];
            }
            for (let area of areas) {
                area.children.sort((a,b) => {
                    let folder = (b.children.length > 0) - (a.children.length > 0);
                    return folder ? folder : a.nome.localeCompare(b.nome);
                });
            }
            this.areas = areas;
            this.items = forest;
            this.loading = false;
        },
        async search() {
            await this.treeAreas(this.query)
            for (let area of this.areas) {
                area.opened = !!this.query;
            }
        },
        async adicionar () {
            if (!(await verifyPermitions.call(this, 'rw_gerenciamentos'))) return;
            if (this.criandoItem) return;
            this.criandoItem = true;
            let item = {
                children: [],
                editing: true,
                id: null,
                idAreaPai: null,
                nome: "",
                opened: false,
            };
            this.areas.push(item);
            this.items.push(item);
        },
        apagar (id) {
            let idx = this.items.findIndex(c => c.id === id);
            if (idx >= 0) {
                this.items.splice(idx, 1);
            }
            let idx2 = this.areas.findIndex(c => c.id === id);
            if (idx2 >= 0) {
                this.areas.splice(idx2, 1);
            }
        },
        cancelarNovo () {
            this.criandoItem = false;
            this.items.pop();
            this.areas.pop();
        },
        adicionadoNovo () {
            this.criandoItem = false;
        },
    }
}
</script>



